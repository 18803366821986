<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Sell Gift Card Transactions</h2>
          <p class="mb-0">Below are Sell Gift Card Transactions/Request</p>
        </div>

        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">
            Reload
          </button>
        </div>
      </div>
      <div class="table-settings mb-4">
        <div class="row align-items-center justify-content-between">
          <form class="col col-md-6 col-lg-3 col-xl-4" @submit.prevent="RefreshData">
            <div class="input-group">
              <span class="input-group-text">
                <span class="fas fa-search"></span>
              </span>
              <input type="text" v-model="search" class="form-control px-1" placeholder="Find Transaction"
                aria-label="Find Transaction" />
            </div>
          </form>
          <div class="col-4 col-md-2 col-xl-1 pl-md-0 d-flex text-right">
            <CountSelector v-if="data" v-model="data.meta.per_page" @updated="RefreshData" class="mr-2" />
            <DataFilterSelector v-model="status" :filters="filters" @updated="RefreshData" />
          </div>
        </div>
      </div>
      <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
        <table class="table table-hover min-h-400">
          <thead>
            <tr>
              <th>#</th>
              <th>Card Name</th>
              <th>Amount</th>
              <th>Payout amount</th>
              <th>Reference</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody v-if="data">
            <SellGiftCardTransactionRow v-for="(dt, index) in data.transactions" :key="index" :dt="dt"
              @open-trade="ReopenTransaction" />
          </tbody>
        </table>
        <Pagination v-if="data" v-model="data.meta.page" :currentPage="data.meta.page" :totalRecord="data.meta.count"
          :dataCount="data.meta.per_page" @updated="RefreshData" />
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import { apiGet, apiPost } from "@/api";
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import Pagination from "@/components/mini/Pagination.vue";
import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";
import { Toast } from "@/utils/notify";
import SellGiftCardTransactionRow from "@/views/cards/constituent/SellGiftCardTransactionRow.vue";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Withdrawals",
  components: {
    DataFilterSelector,
    SellGiftCardTransactionRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const search = ref("");

    const route = useRoute();

    const filters = {
      ALL: "ALL",
      SUBMITTED: "Submitted/New",
      CANCELED: "Canceled",
      PROCESSING: "Processing",
      DONE: "Done/Success",
      FAILED: "Done/Failed",
    };
    const status = ref("ALL");

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/sell/transactions`,
          Object.assign((data.value as any)?.meta ?? {}, {
            status: status.value === "ALL" ? undefined : status.value,
            search: search.value ? search.value : undefined,
          })
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ReopenTransaction = async (id: string) => {
      loading.value = true;

      try {
        await apiPost(`/admin/cards/sell/transactions/${id}/reopen`);
        Toast.fire({
          icon: "success",
          title: "Transaction Reopened Successfully",
        }).then();
        RefreshData();
        loading.value = false;
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to reopen trade: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ReopenTransaction,
      route,
      data,
      loading,
      filters,
      status,
      search,
    };
  },
});
</script>

<style scoped></style>
